$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';

// fonts
$primary-font: effra,
sans-serif;
$secondary-font: raleway,
sans-serif;

//Font Weights
$light: 300;
$regular: 400;
$medium-weight: 500;
$bold: 700;
$heavy: 900;

// colors
$primary-color: #9fe0dd;
$primary-darker-color: #63c7c2;
$secondary-color: #818080;
$tertiary-color: #404040;

//Typography
$heading-color: #262626;
$heading-border-color: rgba(255, 255, 255, 0.25);

//Header
$header-background-color: #ffffff;

//Homepage Banner
$slider-buttons: #ffffff;
$slider-pagers: #ffffff;

//Typography
$heading-text: #31873A;
$heading-text-dark: #333333;
$heading-text-light: #ffffff;
$paragraph-text: #333333;

//Links
$link-color: #343a40;
$link-border-color: rgba(213, 213, 213, 0.5);
$link-border-hover-color: #d5d5d5;

//Butons
$button-color: #31873A;
$button-text: #ffffff;

//Socials
$social-link-color: #999999;

//Images
$figure-caption-border: rgba(52, 145, 67, 0.2);
$figure-caption-color: #333333;
$figure-caption-border-spacers: #ffffff;

//Tabs
$tabs-active: #31873A;
$tabs-inactive: #ececec;
$tabs-text: #333333;
$tabs-border: #d9d9d9;
$tabs-active-border: #31873A;

//Tables
$table-border: #d9d9d9;
$table-head-text: #ffffff;
$table-head-background: #31873A;
$table-head-border: #297336;

//Accordions
$accordion-border: #e6e6e6;
$accordion-border-hover: #31873A;
$accordion-title: #31873A;
$accordion-title-hover: #333333;
$accordion-span: #31873A;
$accordion-span-hover: #333333;

//Top Navigation
$top-nav-link-color: #ffffff;

//Primary Navigation
$mobile-menu-bar-text: #595959;
$mobile-nav-border-color: #e5e5e5;
$mobile-subnav-color: #cccccc;
$mobile-subnav-background-color: rgba(0, 0, 0, 0.8);
$desktop-subnav-flyout-color: rgba(0, 0, 0, .85);
$desktop-subnav-flyout-item-hover-color: rgba(0, 0, 0, 0.5);
$nav-item-hover-color: #1a1a1a;

//Search
$search-background-color: #297336;
$search-close-background-color: #256630;
$search-input-border-color: rgba(255, 255, 255, 0.25);

//News
$news-date-color: #343434;
$news-blurb-color: #343434;
$news-view-all-color: #58ba47;
$news-border-color: #d5d5d5;
$news-item-border-bottom-color: #E6E6E6;
$featured-news-heading-color: #ffffff;

//Events
$events-title-color: #ffffff;
$events-date-color: #ffffff;
$events-blurb-color: #343434;
$events-read-more-color: #404040;
$events-view-all-color: #31873A;
$events-border-color: #d5d5d5;
$events-item-border-bottom-color: #E6E6E6;
$events-featured-heading: #31873A;
$events-slider-buttons: #31873A;
$events-slider-pagers: #31873A;

//Modals
$modal-heading-background: #666666;
$modal-heading-color: #ffffff;

//Forms
$form-field-border-color: #cccccc;
$form-field-text-color: #333333;
$form-checkbox-active-color: #31873A;
$form-radio-button-color: #31873A;
$form-radio-button-border-color: #cccccc;
$form-submit-button-color: #31873A;
$form-submit-button-hover-color: #333333;
$form-submit-button-border-color: #cccccc;
$form-submit-button-text-color: #ffffff;
$form-submit-button-text-hover-color: #ffffff;

//Sidebar
$sidebar-background: #ffffff;

//Footer
$footer-background-color: #1a1a1a;
$footer-text-color: #ffffff;
$footer-link-color: #58ba47;
$footer-link-hover-color: #ffffff;
$footer-social-link-color: #58ba47;
$footer-social-link-hover-color: #ffffff;
$footer-link-after: #535353;
$footer-divider: #535353;
$footer-bottom-link-after: #d5d5d5;
$footer-column-borders: #58ba47;

//Subpage Banner
$subpage-banner-heading-color: #31873A;