img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;

  @include medium-up {
    max-width: 100%;
  }

  &[class*='left'] {
    float: left;
    max-width: 40%;
    margin: 0 20px 15px 0;

    @include medium-up {
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    float: right;
    max-width: 40%;
    margin: 0 0 15px 20px;

    @include medium-up {
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'] {
    margin: 0 auto 15px auto;

    @include medium-up {
      margin: 0 auto 20px auto;
    }
  }
}

figure {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;

  @include medium-up {
    max-width: 100%;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  img[class*='align'] {
    margin: 0 auto;
    float: none;

    @include medium-up {
      margin: 0 auto;
      float: none;
    }
  }

  &[class*='left'] {
    float: left;
    max-width: 40%;
    margin: 0 20px 15px 0;

    @include medium-up {
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    float: right;
    max-width: 40%;
    margin: 0 0 15px 20px;

    @include medium-up {
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'] {
    margin: 0 auto 15px auto;

    @include medium-up {
      margin: 0 auto 20px auto;
    }
  }

  figcaption {
    margin-top: 1px;
    @include fontsize(15 18);
    color: white;
    background: black;
    padding: 10px 25px;
  }
}