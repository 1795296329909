.news-search {

    .btn {
        padding-top: 10px;
        width: 150px;
    }

    input[type='text'] {
        display: block;
        padding: 10px;
        margin-right: 10px;
        border: 1px solid $form-field-border-color;
        background: transparent;
        color: $form-field-text-color;
        font-weight: 300;
        font-size: 16px;
        border-radius: 20px;

        &:focus {
            outline: none;
            border-color: #e9b52b;
        }
    }
}