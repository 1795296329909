﻿/*Chrome*/
input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    border: solid grey 1px;
    background: transparent;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 35px;
    background: grey;
    border: solid grey 1px;
    border-radius: 13px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: goldenrod;
    margin-top: -8px;
    border: solid black 8px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:hover::-webkit-slider-thumb {
    height: 60px;
    width: 60px;
    margin-top: -11px;
}

input[type=range] {
    /* fix for FF unable to apply focus style bug  */
    border: 1px solid white;
    /*required for proper track sizing in FF*/
    width: 100%;
    position: relative;
    z-index: 100;
    /*    border-radius: 15px;
*/
}

/*Ticks*/
.flex-container {
    /* We first create a flex layout context */
    display: flex;
    /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
    flex-flow: row wrap;
    /* Then we define how is distributed the remaining space */
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: -52px;
    z-index: 1;
}

.flex-item {
    background: grey;
    padding: 5px;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    line-height: 150px;
    color: white;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    border-radius: 50%;
}

.flex-container-img {
    text-align: center;
    position: relative;
    z-index: 2000;
    margin-bottom: 20px;
}

.sliderImg {
    display: inline-block;
    border: solid black 3px;
    border-radius: 3px;
    padding: 5px;
    box-shadow: 5px 3px 3px grey;
    width: 500px;
    height: 500px;
}

.textSlider {
    position: sticky;
    bottom: 7rem;
    margin-bottom: 2rem;
    width: 50%;

}
