.form-box-content {
    .content-form {
        margin: 0;

        .form-header {
            p {
                padding: 0;
            }
        }

        .form-builder-section {
            fieldset {
                width: 100%;

                input[type='text'],
                input[type='file'],
                textarea,
                select {
                    display: block;
                    margin-top: 5px;
                    margin-bottom: 30px;
                    padding: 10px;
                    width: 100%;
                    border: 1px solid $form-field-border-color;
                    background: whitesmoke;
                    color: $form-field-text-color;
                    font-weight: 300;
                    font-size: 18px;
                    border-radius: 50px;

                    &:focus {
                        outline: none;
                        border-color: #e9b52b;
                        background: transparent;
                    }
                }

                textarea {
                    padding: 14px;
                    font-style: normal;
                    font-size: 18px;
                    border-radius: 10px;
                }

                label {
                    color: black;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 20px;
                }

                select {
                    margin-top: 5px;
                    width: 100%;
                    border: 1px solid $form-field-border-color;
                    background: transparent;
                    color: $form-field-text-color;
                    font-weight: 300;
                    font-size: 18px;
                    background: url('/themes/athena/assets/dist/images/Down-Arrow.png') no-repeat;
                    background-position: 98% 50%;
                    background-size: 12px;
                    padding-right: 10px;

                    &:focus {
                        outline: none;
                        border-color: #e9b52b;
                    }
                }

                input[type='checkbox'] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    margin-top: 20px;

                    + label {
                        position: relative;
                        display: inline-block;
                        padding-left: 30px;
                        font-weight: normal;
                        margin-bottom: 15px;
                        margin-top: 20px;

                        &:before,
                        &:after {
                            position: absolute;
                            display: inline-block;
                            content: '';
                        }

                        &:before {
                            top: 5px;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            border: 1px solid $primary-darker-color;
                        }

                        &:after {
                            top: 5px;
                            left: 0;
                            content: none;
                        }
                    }

                    &:checked + label {
                        &:after {
                            content: '\f00c';
                            color: white;
                            font-family: 'Font Awesome 5 Free';
                            font-weight: 900;
                            font-size: 14px;
                            left: 3px;
                            top: 2px;
                        }

                        &:before {
                            content: '';
                            background: $form-checkbox-active-color;
                        }
                    }

                    &:focus + label {
                        &:before {
                            outline: 5px auto -webkit-focus-ring-color;
                        }
                    }
                }

                button,
                html input[type='button'],
                input[type='reset'],
                input[type='submit'] {
                    margin-left: 0;
                    padding: 15px 40px;
                    max-width: 300px;
                    width: auto;
                    width: 100%;
                    border: 1px solid black;
                    background: black;
                    color: black;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 18px;
                    cursor: pointer;
                    -webkit-appearance: button;

                    &:hover,
                    &:focus {
                        outline: 5px auto -webkit-focus-ring-color;
                        border-color: black;
                        background: black;
                        color: black;
                    }
                }

                .hide {
                    display: none;
                }

                .radio-list {
                    margin-top: 30px;

                    input[type='radio'] {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;

                        + label {
                            position: relative;
                            display: inline-block;
                            padding-left: 30px;
                            font-weight: normal;
                            padding-top: 5px;
                            margin-top: 5px;

                            &:before {
                                position: absolute;
                                display: inline-block;
                                font-weight: 500;
                                left: 0;
                                color: $form-radio-button-color;
                                font-size: 20px;
                                content: '';
                                width: 21px;
                                top: 6px;
                                height: 21px;
                                border: 1px solid $form-radio-button-border-color;
                                border-radius: 25px;
                            }
                        }

                        &:checked + label {
                            &:after {
                                content: '\f111';
                                color: $form-radio-button-color;
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 900;
                                font-size: 10px;
                                left: 6px;
                                top: 6px;
                                position: absolute;
                            }
                        }

                        &:focus + label {
                            &:before {
                                outline: 5px auto -webkit-focus-ring-color;
                            }
                        }
                    }

                    ul {
                        padding: 10px 0;

                        li {
                            margin-bottom: 0;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                .span-full {
                    display: inline-block;
                    margin-bottom: 10px;
                    padding-right: 0;
                    width: calc(100% - 15px);
                }

                .span-2 {
                    display: inline-block;
                    padding-right: 25px;
                    width: calc((100% - 5px) / 2);

                    &:nth-of-type(2n) {
                        padding-right: 0 !important;
                    }
                }

                .span-3 {
                    display: block;
                    width: 100%;
                }

                .span-4 {
                    display: block;
                    width: 100%;
                }

                .span-5 {
                    display: block;
                    width: 100%;
                }

                .col-last {
                    clear: right;
                    margin-right: 0;
                }

                .error-msg {
                    position: relative;
                    right: 0;
                    bottom: 0;
                    float: left;
                }
            }
        }

        .required {
            label {
                &:after {
                    font-family: 'Font Awesome 5 Free';
                    content: '\f069';
                    font-weight: 600;
                    color: black;
                    font-size: .75rem;
                    margin-left: .5rem;
                }
            }
        }

        .form-builder-submit-controls {
            input[type='submit'] {
                padding: 15px 40px;
                margin-left: 0;
                background: $form-submit-button-color;
                border: 1px solid $form-submit-button-border-color;
                cursor: pointer;
                border-radius: 50px;
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: 2px;
                color: $form-submit-button-text-color;
                font-weight: 700;

                &:hover,
                &:focus {
                    background: $form-submit-button-hover-color;
                    color: $form-submit-button-text-hover-color;
                }
            }
        }
    }
}
