﻿#parallax1 {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/handoffate-header-image.jpg");
  /* Set a specific height */
  min-height: 800px;
}

#parallax2 {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/parallax-2.jpg");
  /* Set a specific height */
  min-height: 500px;
}

#parallax3 {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/parallax-3.jpg");
  /* Set a specific height */
  min-height: 500px;
}

.parallaxSubTap {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/parallax-sub.jpg");
  /* Set a specific height */
  min-height: 575px;
}

.parallaxSubFood {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/foodParallax.jpeg");
  /* Set a specific height */
  min-height: 575px;
}

.parallaxSubEvents {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/gallery/image-gallery-5.jpg");
  /* Set a specific height */
  min-height: 575px;
}

.parallaxSubContact {
  /* The image used */
  background-image: url("/themes/hand-of-fate/assets/src/images/contactParallax.PNG");
  /* Set a specific height */
  min-height: 575px;
}

.parallax {
  /* Create the parallax scrolling effect */
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: darkgray;
  background-image: url("/themes/hand-of-fate/assets/src/images/handoffate-header-image.jpg");
  margin-bottom: 30px;

  .text-light {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.65);
  }
}

.fixed-top {
  transition: all 300ms linear;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.gallery {
  background: #eee;
  width: 100%;
}

.gallery-cell {
  height: 400px;
  margin-right: 10px;
  background: #8c8;
  counter-increment: gallery-cell;
}

@media (max-width: 640px) {
  .verifyContent {
    margin-top: -220px;
  }

  .verifyLinks {
    margin-top: -210px;
  }

  .parallax {
    background-attachment: initial !important;
  }

  .logoVerify {
    height: 240px !important;
    width: 180px !important;
  }
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

#rightNav {
  position: fixed;
  right: 0px;
  top: 50%;
  z-index: 1000;
  font-size: 1.25rem;
  display: none;

  a {
    color: #e9b52b !important;
  }
}

body {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

header ul {
  list-style-type: none;
}

.li1 {
  display: inline-flex;
}

.a2 {
  color: #212529 !important;
}

.frontPageImages {
  max-height: 464px;
  margin: auto;
}

/*verify video*/
.video-foreground {
  z-index: -100;
}

.verify-background {
  z-index: -200;
  height: 100%;
}

.verifyMain iframe {
  @media only screen and (max-width: 1400px) {
    display: none;
  }
  height: 100%;
}

.bg-custom {
  background-color: #1a1a1a !important;
}

@media only screen and (max-width: 960px) {
  .navbar {
    background-color: #1a1a1a !important;
  }
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(233, 181, 43, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgba(233, 181, 43, 1) !important;
}

/*logo*/
.logoInitial {
  width: 100px;
  max-width: 207px;

  @media only screen and (min-width: 990px) {
    width: 207px;
  }
}

.logoScroll {
  width: 100px;

  @media only screen and (min-width: 990px) {
    width: 100px;
  }
}


/*image overlay*/
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.videoWrapper img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  cursor: pointer;
}

.videoWrapper:after {
  content: "";
  position: absolute;
  display: block;
  background: url(play-button.png) no-repeat 0 0;
  top: 45%;
  left: 45%;
  width: 46px;
  height: 36px;
  z-index: 30;
  cursor: pointer;
}

.videoWrapper .embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* image poster clicked, player class added using js */
.video .player img {
  display: none;
}

.video .player:after {
  display: none;
}

.flickity-page-dots {
  display: none;
}

/*verify*/
.logoVerify {
  height: 320px;
  width: 260px;
}

.gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8603816526610644) 16%, rgba(0, 0, 0, 0.24413515406162467) 52%, rgba(0, 0, 0, 0.8575805322128851) 83%, rgba(0, 0, 0, 1) 100%);
}

.verifyContent {
  position: relative;
  height: 850px;
  z-index: 2000;
}

.socialLinks {
  color: #e9b52b;
  font-size: 1.25rem !important;
}

.socialLinksFooter {
  box-shadow: 0 0 15px rgb(230, 230, 230);
}

.parallaxGradient1 {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4514180672268907) 38%, rgba(0, 0, 0, 1) 100%);
}

.brews {
  background: repeating-linear-gradient(-45deg, rgba(211, 211, 211, .45), rgba(211, 211, 211, .45) 2px, white 2px, white 10px);
  padding: 50px 0;

  .row {
    max-width: 1200px;
  }
}

.linksContainer {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 50px;

  h1 {
    color: #e9b52b;
  }

  p,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }

  .row {
    max-width: 1200px;
  }

  .col {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

body {
  font-family: trade-gothic-next, sans-serif;

  a {
    font-family: trade-gothic-next-compressed, sans-serif !important;
    font-size: 22px !important;
  }

  h1,
  h2 {
    font-family: lexia-advertising, serif;
  }

  h3 {
    font-family: lexia, serif;
    font-weight: bold;
  }

  h1 {
    font-size: 30px;
  }
}

.brews img {
  width: 440px;
  height: 320px;
  object-fit: cover;

  @media only screen and (min-width: 576px) {
    width: 636px;
    height: 416px;
  }

  @media only screen and (min-width: 768px) {
    width: 636px;
    height: 276px;
  }

  @media only screen and (min-width: 992px) {
    width: 350px;
    height: 280px;
  }

  @media only screen and (min-width: 1200px) {
    width: 625px;
    height: 320px;
  }
}

.navbar a {
  font-weight: 400 !important;
  font-size: 1.75rem !important;
  margin-right: 1rem !important;
}

.btn {
  line-height: 22px !important;
}

.news-post-image img {
  height: 200px !important;
}

a {
  text-decoration-color: #e9b52b !important;
}

.p-3 ul li .nav-link {
  color: #e9b52b !important;

  &:hover {
    text-decoration: underline;
  }
}

.featuredNews {
  max-width: 1200px;
}