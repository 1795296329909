// footer
footer {
  background-color: $footer-background-color;
  // padding: 60px 20px 0;
  color: $footer-text-color;

  // @include medium-up {
  //   padding: 60px 50px 0;
  // }

  .footer-group {
    width: 100%;
    display: block;

    @include medium-up {
      display: flex;
    }
  }

  .footer-top {
    display: block;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;

    @include medium-up {
      display: flex;
    }

    .footer-copy {
      position: relative;
      width: 100%;
      font-size: 18px;
      font-weight: $medium-weight;

      p {
        margin: 0;
      }
    }

    .footer-social-links {
      width: 100%;
      text-align: left;
      padding-top: 25px;

      @include medium-up {
        text-align: right;
        padding-top: 0;
      }

      .social-nav {
        position: relative;

        @include medium-up {
          text-align: right;
        }

        ul {
          list-style: none;
          display: inline-flex;
          padding: 0;

          li {
            margin-right: 25px;

            a {
              color: $footer-social-link-color;
              font-size: 18px;

              &:hover,
              &:focus {
                color: $footer-social-link-hover-color;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .footer-links {
      position: relative;
      width: 100%;

      @include medium-up {
        margin-top: 25px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 25px 0 0;
          position: relative;

          a {
            color: $footer-link-color;
            position: relative;
            text-decoration: none;
            font-weight: $regular;
            font-size: 18px;

            &:hover,
            &:focus {
              color: $footer-link-hover-color;
            }
          }

          &:first-of-type {
            a {
              padding-left: 0;
            }
          }
        }
      }

      @include medium-up {

        ul {
          display: inline-flex;

          li {
            padding: 0;

            a {
              padding: 0 12px;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    padding-top: 30px;
    padding-bottom: 60px;
    border-top: 2px solid $footer-divider;

    .footer-information {
      font-size: 14px;

      .col {
        position: relative;
        padding-bottom: 0;
        display: inline-table;
        border-left: 2px solid $footer-column-borders;
        margin-bottom: 30px;
        padding: 0 20px;

        @include medium-up {
          padding: 0 25px;
        }

        p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      p {
        margin: 10px 0;
        font-size: 16px;

        strong {
          font-weight: $bold;
          font-size: 18px;
        }

        a {
          color: white;
          text-decoration: none;
          position: relative;
          width: auto;
          display: inline-block;

          &:hover {

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// .footer-antilles {
//   display: flex;
//   width: 100%;
//   align-items: center;
//   background: black;
//   position: absolute;
//   left: 0;
//   text-align: center;
//   align-content: center;
//   justify-content: center;
//   padding: 40px 50px;

//   .brand {
//     display: inline-block;
//     position: relative;
//     margin-right: 25px;

//     &:after {
//       position: absolute;
//       right: -25px;
//       width: 1px;
//       height: 100%;
//       background-color: #5b6168;
//       content: '';
//       top: 0;
//     }
//   }

//   .tagline {
//     margin-left: 25px;
//     text-transform: uppercase;
//     letter-spacing: 2px;
//     font-size: 14px;
//     position: relative;
//     display: inline-block;

//     a {
//       color: white;
//       text-decoration: none;

//       &:hover {
//         text-decoration: underline;
//       }
//     }
//   }
// }

.footer-antilles {
  img {
    display: inline-block;
  }
}