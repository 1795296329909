// .two-column,
// .three-column,
// .four-column,
// .five-column,
// .six-column {
//     @include medium-up {
//         margin: 0;
//     }
// }

// .two-column .col.two-thirds {
//     @include medium-up {
//         width: 67%;
//     }
// }

// .two-column .col.one-third {
//     @include medium-up {
//         width: 33%;
//     }
// }

.two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
	@include medium-up {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
	}  margin: 0;
	padding: 0;
	list-style: none;
	.col,
	li.col {
		width: 100%;
	}

	li.col {
		margin: 0;
		padding: 0;

		&:before {
			display: none;
		}
	}
}

.two-column .col,
ul.two-column li.col {
	@include medium-up {
		width: calc(50% - 20px);
		padding: rem-calc(0 25px 25px 25px);
	}
}

.three-column .col,
ul.three-column li.col {
	@include medium-up {
		width: calc(50% - 20px);
		padding: rem-calc(0 25px 25px 25px);
	}

@include large-up {
		width: calc(33.3% - 20px);
	}
}

.four-column .col,
ul.four-column li.col {
	@include medium-up {
		width: calc(50% - 20px);
		padding: rem-calc(0 25px 25px 25px);
	}

@include large-up {
		width: calc(25% - 20px);
	}
}

.five-column .col,
ul.five-column li.col {
	@include medium-up {
		width: calc(50% - 24px);
		padding: rem-calc(0 25px 25px 25px);
	}

@include large-up {
		width: calc(20% - 24px);
	}
}

.six-column .col,
ul.six-column li.col {
	@include medium-up {
		width: calc(50% - 20px);
		padding: rem-calc(0 25px 25px 25px);
	}

@include large-up {
		width: calc(20% - 20px);
	}

@include xlarge-up {
		width: calc(16.66666666666667% - 20px);
	}
}